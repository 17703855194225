  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/2-4-2-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li><a href='/expertise-assure/risques/'>Catastrophe Naturelle</a></li><li>Après inondation</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Le risque inondation en région PACA </h1>

<p>En région Provence Alpes Côte-d'Azur, près de 600 000 personnes sont concernées par le risque inondation. Les dernières inondations d’Octobre 2020 dans les vallées de la Roya et de la Vésubie, nous rappellent les conséquences particulièrement dommageables des inondations générées par des crues soudaines.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Après inondation" />
</div>


<p>Suite à un sinistre inondation, votre contrat d’assurance MRH (multirisques habitation) couvre généralement les dommages matériels consécutifs à cet aléa naturel.</p>


<h1>Que faire suite à un sinistre inondation ?  </h1>

<p>Suite à la promulgation de l’Arrêté de catastrophe naturelle, l’assuré a 10 jours pour effectuer sa déclaration de sinistre auprès de son assureur.</p>

<p>Un expert d’assurance sera alors mandaté pour évaluer vos dommages et les chiffrer.</p>

<p>En cas de désaccord avec les décisions de l’assurance, vous avez la possibilité d’engager votre propre expert, à savoir un expert d’assuré spécialisé en gestion de sinistre inondation. </p>

<h2>Le recours à l’expertise d’assuré “inondation”</h2>

<p>L’Expert d’assuré inondation intervient sur site afin :</p>
<ul><li>de faire un listing précis et détaillé de vos dommages,</li>
<li>d’établir un chiffrage estimatif des dommages et des travaux de reprise,</li>
<li>de négocier avec votre assurance le montant indemnitaire,</li>
<li>de vous accompagner jusqu’à juste indemnisation de votre sinistre</li></ul>

<p>Le cabinet RV Expertises propose un accompagnement personnalisé pour la défense de vos intérêts afin d’obtenir LA juste indemnisation de votre sinistre.</p>

<p>Le cabinet RV Expertises travaille avec la structure <u>Inf’eau Risk</u>, spécialiste de la gestion du risque inondation, sur les dossiers “après sinistre inondation”, pour une meilleure analyse et défense des intérêts de l’assuré. </p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert d’assuré</a>
    </div>
</div>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/risques/inondation/' className='active'>Après inondation</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details